Profit.Grootboekrekeningen = {
    /**
     * Bij selecteren Soort zullen de bijbehorende rubrieken opgehaald worden
     */
    getRubriekenBySoort: function() {
        $('.js-grootboek-soort input').click(function() {
            var soortID = $(this).data('soort-id');
            var rubriekID = $('.js-rubriek option:selected').val();
            var loader = $('.loader-wrap');
            loader.show();

            $.ajax({
                data: {
                    soortID: soortID,
                    rubriekID: rubriekID
                }
            }).done(function (data) {
                $('.js-rubriek')
                    .html(data)
                    .focus();

                loader.hide();
            }).fail(function (fail) {
                console.log('fail '+fail.responseText);
            });
        });
    },

    init: function(){
        this.getRubriekenBySoort();
    }
}