Profit.Rapporten = {
    /**
     * Bij selecteren Periode rapport zal de juiste data opgehaald worden
     */
    getBalansPeriode: function() {
        $('.js-rapport-periode-select').change(function() {
            var periode = this.value;
            var loader = $('.loader-wrap');
            loader.show();

            $.ajax({
                data: {
                    periode: periode
                }
            }).done(function (data) {
                $('.js-rapport-title').text(data['periodeTitle']);

                // Get periode from url and place it with the new periode
                var printLink = $('.js-rapport-print').attr("href");
                var newPrintLink = printLink.substr(printLink.lastIndexOf('/') + 1);
                $('.js-rapport-print').attr("href", printLink.replace(newPrintLink, data['periode']));

                $('.js-balans-rapport')
                    .html(data['view'])
                    .focus();

                loader.hide();
            }).fail(function (fail) {
                console.log('fail '+fail.responseText);
            });
        });
    },

    init: function(){
        this.getBalansPeriode();
    }
}