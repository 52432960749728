Profit.Dagjournalen = {

  grootboekrekeningRowHtml: $('.js-grootboekEmptyRow').length ? $('.js-grootboekEmptyRow')[0].outerHTML: '',
  dagboekSoortID: 0,
  grootboekSoortID: 0,
  grootboekMutatieBedrag: 0,
  dagboekEindSaldo: $(".js-dagjournaal-startsaldo").text(),

  /**
   * Get data dagboek by selecting dagboek
   */
  getDagboekData: function () {
    var self = this;

    $('.js-dagjournaal-dagboek-select, .js-dagjournaal-dagboek-datum').change(function () {
      var selectedValue = $('.js-dagjournaal-dagboek-select').val();
      var loader = $('.loader-wrap');
      var dagboekDatum = $('.js-dagjournaal-dagboek-datum').val();

      //$(".js-dagjournaal-grootboek-bedrag".change());

      loader.show();

      $.ajax({
        data: {
          dagboekID: selectedValue,
          dagboekDatum: dagboekDatum
        }
      }).done(function (data) {
        // Save Dagboek Soort in property
        self.dagboekSoortID = data['dagboek']['Soort'];
        self.dagboekEindSaldo = data['dagboek']['Startsaldo'];

        // Replace comma to dot, so whe can count with it
        self.dagboekEindSaldo = self.dagboekEindSaldo.replace(/,/g, '.');
        self.dagboekEindSaldo = parseFloat(self.dagboekEindSaldo);

        $('.js-dagjournaal-dagboek-stuknummer').val(data['dagboek']['Stuknummer']);
        $('.js-dagjournaal-dagboek-periode')
          .val(data['dagboek']['Periode'])
          .prop('readonly', 'readonly');

        if (!$('.js-dagjournaal-grootboek-rekeningen').children().length) {
          $('.js-dagjournaal-grootboek-rekeningen').html(data['grootboekrekeningen']);
        }

        // Startsaldo invullen
        $('.js-dagjournaal-startsaldo, .js-dagjournaal-eindsaldo').text(data['dagboek']['Startsaldo']);

        // Heading totaal blok veranderen adv keuze dagboek en rekening
        if ($('.js-dagoek-eindsaldo-heading').length) {
          if (self.dagboekSoortID === 1) {
            $('.js-dagoek-eindsaldo-heading').text('Tegenrekening ' + data['dagboek']['Naam']);
          }
          else {
            $('.js-dagoek-eindsaldo-heading').text('Gesloten dagboek');
          }
        }

        loader.hide();
      }).fail(function (fail) {
        console.log('fail ' + fail.responseText);
      });
    });
  },

  /**
   * Get data by selecting Grootboek rekening.
   */
  getGrootboekSoortData: function () {
    var loader = $('.loader-wrap');
    var grootBoekID;
    var factuur;
    var parent = this;
    var selectoren;

    $(document).on('change', '.js-dagjournaal-grootboek-rekeningen', function () {
      var $grootBoekRow = $(this).closest('.js-grootboekrekeningRow');

      loader.show();

      grootBoekID = $(this).val();

      // Eerst eerdere gevulde velden legen
      parent.clearFields($grootBoekRow);

      // Opnieuw totaal bedrag uitrekenen
      parent.calculatePrices();

      $.ajax({
        data: {
          grootBoekID: grootBoekID
        }
      }).done(function (data) {
        var grootboekData = data['Grootboek'];
        var $relatieVeld = $grootBoekRow.find(".js-dagjournaal-grootboek-relatie");
        var $factuurVeld = $grootBoekRow.find(".js-dagjournaal-grootboek-factuur");
        parent.grootboekSoortID = grootboekData['Soort'];

        if(parent.grootboekSoortID  == data['GrootboekSoortIDs']['GSDEBITEUREN'] ||
          parent.grootboekSoortID  == data['GrootboekSoortIDs']['GSCREDITEUREN'] ||
          parent.grootboekSoortID  == data['GrootboekSoortIDs']['GSPRODUCTVOORRAAD'] ||
          parent.grootboekSoortID  == data['GrootboekSoortIDs']['GSPRODUCTRESULTAAT']) {
            selectoren = grootboekData['Selectoren'];
        }

        if (data['Selectoren']) {
          $relatieVeld
            .html(data['Selectoren'])
            .focus()
            .prop('disabled', false);

          $factuurVeld.prop('disabled', false);
        }
        else {
          // Gebruiker kan alleen omschrijving en bedrag invullen
          $relatieVeld.prop('disabled', 'disabled');
          $factuurVeld.prop('disabled', 'disabled');
        }

        loader.hide();
      }).fail(function (fail) {
        console.log('fail ' + fail.responseText);
      });
    });

    $(document).on('change', '.js-dagjournaal-grootboek-relatie', function () {
      var self = this;

      if(!$(self).val().length) return;

      loader.show();
      var $grootBoekRow = $(this).closest('.js-grootboekrekeningRow');
      grootBoekID = $grootBoekRow.find(".js-dagjournaal-grootboek-rekeningen option:selected").val();
      var currentRowIndex = $grootBoekRow.index();

      if(currentRowIndex > 0) currentRowIndex = currentRowIndex - 1;

      $.ajax({
        data: {
          grootBoekID: grootBoekID,
          currentRowIndex: currentRowIndex,
          relatieID: $(self).val()
        }
      }).done(function (data) {
        var inkoopPrijs = 0;

        if(parent.grootboekSoortID  == data['GrootboekSoortIDs']['GSPRODUCTVOORRAAD']) {
          inkoopPrijs = selectoren[$(self).val()]["InkoopPrijs"];
        }

        // Dagboek is verkoop en GB is Debiteuren of DB is Inkoop en GB is crediteuren. Systeem vult als factuurnummer het stuknummer in
        if (
          (parent.dagboekSoortID == data['DagboekSoortIDs']['DSVERKOOP'] && parent.grootboekSoortID == data['GrootboekSoortIDs']['GSDEBITEUREN']) ||
          (parent.dagboekSoortID == data['DagboekSoortIDs']['DSINKOOP'] && parent.grootboekSoortID == data['GrootboekSoortIDs']['GSCREDITEUREN'])) {
          $grootBoekRow.find('.js-dagjournaal-grootboek-factuur').val($('.js-dagjournaal-dagboek-stuknummer').val())
        }
        else {
          // lijst met facturen
          $grootBoekRow.find('.js-dagjournaal-grootboek-factuur').replaceWith(data['FacturenHTML']);

          $grootBoekRow.find('.js-dagjournaal-grootboek-factuur').focus();
          factuur = data['Facturen'];

          $(document).on('change', '.js-dagjournaal-grootboek-factuur', function () {
            var self = this;

            // Lijst facturen
            if(parent.grootboekSoortID == data['GrootboekSoortIDs']['GSDEBITEUREN'] ||
              parent.grootboekSoortID == data['GrootboekSoortIDs']['GSCREDITEUREN']) {

              if(factuur.length > 0) {
                var aFactuur = factuur[$(self).val()];

                $grootBoekRow.find('.js-dagjournaal-grootboek-omschrijving').val(aFactuur['Omschrijving']);
                $grootBoekRow.find('.js-dagjournaal-grootboek-bedrag').val(-parseFloat(aFactuur['Bedrag']));
              }
            }

            // Product aantal
            if(parent.grootboekSoortID == data['GrootboekSoortIDs']['GSPRODUCTVOORRAAD']) {
              var aantalProducten = parseFloat($(self).val());
              $grootBoekRow.find('.js-dagjournaal-grootboek-omschrijving').val("");
              $grootBoekRow.find('.js-dagjournaal-grootboek-bedrag').val(aantalProducten * inkoopPrijs);
            }
          });
        }

        loader.hide();
      }).fail(function (fail) {
        console.log('fail ' + fail.responseText);
      });
    });

    var index = 0;
    var newIndex = 0;

    $(document).on('keydown', '.js-dagjournaal-grootboek-bedrag', function (e) {
      // Create new row when using tab key
      if (e.keyCode == 9) {
        $('.js-grootboekrekeningRow:last').after(parent.grootboekrekeningRowHtml);
        $('.js-grootboekrekeningRow:last').removeClass('dagjournaal-grootboekrekeningRow');

        index = $(this).attr('name').replace(/[^0-9]/g,"");
        newIndex = parseInt(index) + 1;

        // Increment mutaties[] array by 1 each time new row added
        $('.js-grootboekrekeningRow:last .form-control').each(function () {
          $(this).attr('name', $(this).attr('name').replace("[0]", "[" + newIndex + "]"));
        });

        if ($('.js-grootboekrekeningRow').length > 1) {
          $('.js-grootboek-rekening-remove').addClass('show');
        }
      }
    });
  },

  clearFields: function (parentField) {
    $(parentField).find('input').each(function () {
      $(this).val('').html('');
    });
  },

  addGrootboekRekening: function () {
    var self = this;
    var index = 0;
    var newIndex = 0;

    $(document).on('click', '.js-grootboek-rekening-add', function () {

      index = $('.js-grootboekrekeningRow:last .js-dagjournaal-grootboek-rekeningen').attr('name').replace(/[^0-9]/g,"");
      newIndex = parseInt(index) + 1;

      $('.js-grootboekrekeningRow:last').after(self.grootboekrekeningRowHtml);
      $('.js-grootboekrekeningRow:last').removeClass('dagjournaal-grootboekrekeningRow');

      // Tab to first form control of new row
      $('.js-grootboekrekeningRow:last .form-control').first().focus();

      // Increment mutaties[] array by 1 each time new row added
      $('.js-grootboekrekeningRow:last .form-control').each(function () {
        $(this).attr('name', $(this).attr('name').replace("[0]", "[" + newIndex + "]"));
      });

      if ($('.js-grootboekrekeningRow').length > 1) {
        $('.js-grootboek-rekening-remove').addClass('show');
      }


    });
  },

  removeGrootboekRekening: function () {
    var self = this;

    $(document).on('click', '.js-grootboek-rekening-remove', function () {
      if($('.js-grootboekrekeningRow').length > 2) {
        $(this).closest('.js-grootboekrekeningRow').remove();

        // Opnieuw totaal bedrag uitrekenen
        self.calculatePrices();

        if ($('.js-grootboekrekeningRow:visible').length == 1) {
          $('.js-grootboek-rekening-remove').removeClass('show');
        }
      }
    });
  },

  calculateCurrentPrice: function () {
    var timeoutId = 0;
    var price = 0;
    var oldprice = 0;
    var totalPrices;
    var prices;
    var parent = this;

    $(document).on('change keyup', '.js-dagjournaal-grootboek-bedrag', function (e) {
      var self = this;
      totalPrices = 0;

      clearTimeout(timeoutId);

      // Wacht 500ms voor ophalen bedrag
      timeoutId = setTimeout(function () {
        oldprice = price;
        price = parseFloat($(self).val());

        // Alleen uitvoeren als huidig bedrag is aangepast
        if (oldprice != price) {
          prices = 0;

          parent.calculatePrices();
        }

      }, 500);

    });
  },

  calculatePrices: function () {
    var prices;
    var totalPrices = 0;
    var self = this;

    // Door alle bedragen lopen en optellen
    $('.js-dagjournaal-grootboek-bedrag').each(function () {
      if ($(this).val().length == 0) {
        prices = 0;
      }
      else {
        prices = parseFloat($(this).val());
      }

      totalPrices -= prices;
    });

    totalPrices = parseFloat(totalPrices);

    var newPrice = parseFloat(self.dagboekEindSaldo + totalPrices);

    newPrice = parseFloat(Math.round(newPrice * 100) / 100).toFixed(2).replace('.', ',');

    $('.js-dagjournaal-mutatie').text(parseFloat(Math.round(totalPrices * 100) / 100).toFixed(2).replace('.', ','));

    $('.js-dagjournaal-eindsaldo').text((newPrice));
  },

  cleanFloatNumber: function (userValue) {
    //clean the user input and scrub out non numerals
    var cleanValue = userValue.replace(/,/g, "");
    return cleanValue;
  },

  init: function () {
    // Replace comma to dot, so whe can count with it
    this.dagboekEindSaldo = this.dagboekEindSaldo.replace(/,/g, '.');
    this.dagboekEindSaldo = parseFloat(this.dagboekEindSaldo);

    this.getDagboekData();
    this.getGrootboekSoortData();
    this.addGrootboekRekening();
    this.removeGrootboekRekening();
    this.calculateCurrentPrice();
  }
}
