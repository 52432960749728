Profit.Orders = {

    loader: $('.loader-wrap'),

    getData: function (OrderDate) {
        var parent = this;

        parent.loader.show();

        $.ajax({
            data: {
                OrderDatum: OrderDate
            }
        }).done(function (data) {
            $(".js-order-nummer").val(data["orderData"]["SortID"]);
            $(".js-order-debiteuren").html(data["debiteurenView"])

            parent.loader.hide();
        }).fail(function (fail) {
            console.log('fail ' + fail.responseText);
        });
    },
    getDebiteurData: function () {

    },
    init: function () {

        var parent = this;

        /**
         * Init show Debiteuren list
         */
        if($(".js-order-datum").length) {
            $(".js-order-datum").change(function() {
                parent.getData($(this).val());
            });

            $(".js-order-debiteuren").change(function() {

            });

            this.getData($(".js-order-datum").val());

            // Get artikelen list on page load
        }
    }
}
