Profit.Dagboeken = {

    /**
     * Enable/disable grootboek lijst adv gekozen dagboek soort
     */
    setGrootboekSelect: function () {

        if($('.js-dagboek-soort input:checked').val() == 1) {
            $('.js-dagboek-tegenrekening').removeAttr('disabled');

            /**
             * Niet tonen tegenrekeningen lijst
             */
            $('.js-dagboek-periodieken').addClass('hide').removeClass('show');
        }
        else {
            $('.js-dagboek-tegenrekening').prop('disabled', 'disabled').val('');

            /**
             * Tonen tegenrekeningen lijst
             */
            $('.js-dagboek-periodieken').addClass('show').removeClass('hide');
        }

        $('.js-dagboek-soort input').click(function () {
            if($(this).val() == 1) {
                $('.js-dagboek-tegenrekening').removeAttr('disabled');

                /**
                 * Niet tonen tegenrekeningen lijst
                 */
                $('.js-dagboek-periodieken').addClass('hide').removeClass('show');
            }
            else {
                $('.js-dagboek-tegenrekening').prop('disabled', 'disabled').val('');

                /**
                 * Tonen tegenrekeningen lijst
                 */
                $('.js-dagboek-periodieken').addClass('show').removeClass('hide');
            }
        });
    },

    init: function () {
        this.setGrootboekSelect();
    }
}