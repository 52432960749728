
if(typeof(Profit) == 'undefined') {
    var Profit = {};
}

$(document).ready(function() {

    $.each(Profit,function(index, value){
       this.init();
    });
});