Profit.Artikelen = {

    setVoorraadSelect: function () {

        if($('.js-artikel-heeftVoorraad input:checked').val() == 0) {
            $('.js-artikel-inVoorraad').prop('disabled', 'disabled').val('');
            $('.js-artikel-decimalen').prop('disabled', 'disabled').val('');
            $('.js-artikel-inkoopPrijs').prop('disabled', 'disabled').val('');
            $('.js-artikel-inkoopRek').prop('disabled', 'disabled').val('');
            $('.js-artikel-voorraadRek').prop('disabled', 'disabled').val('');
            $('.js-artikel-herwaarderingsRek').prop('disabled', 'disabled').val('');
            $('.js-artikel-waardeGegevens').addClass('hide').removeClass('show');
        }
        else {
            $('.js-artikel-inVoorraad').removeAttr('disabled');
            $('.js-artikel-decimalen').removeAttr('disabled');
            $('.js-artikel-inkoopPrijs').removeAttr('disabled');
            $('.js-artikel-inkoopRek').removeAttr('disabled');
            $('.js-artikel-voorraadRek').removeAttr('disabled');
            $('.js-artikel-herwaarderingsRek').removeAttr('disabled');
            $('.js-artikel-waardeGegevens').addClass('show').removeClass('hide');
        }

        $('.js-artikel-heeftVoorraad input').click(function () {
            if($(this).val() == 0) {
                $('.js-artikel-inVoorraad').prop('disabled', 'disabled').val('');
                $('.js-artikel-decimalen').prop('disabled', 'disabled').val('');
               $('.js-artikel-inkoopPrijs').prop('disabled', 'disabled').val('');
                $('.js-artikel-inkoopRek').prop('disabled', 'disabled').val('');
                $('.js-artikel-voorraadRek').prop('disabled', 'disabled').val('');
                $('.js-artikel-herwaarderingsRek').prop('disabled', 'disabled').val('');
                $('.js-artikel-waardeGegevens').addClass('hide').removeClass('show');
            }
            else {
                $('.js-artikel-inVoorraad').removeAttr('disabled');
                $('.js-artikel-decimalen').removeAttr('disabled');
                $('.js-artikel-inkoopPrijs').removeAttr('disabled');
                $('.js-artikel-inkoopRek').removeAttr('disabled');
                $('.js-artikel-voorraadRek').removeAttr('disabled');
                $('.js-artikel-herwaarderingsRek').removeAttr('disabled');
                $('.js-artikel-waardeGegevens').addClass('show').removeClass('hide');
            }
        });
    },

    init: function () {
        this.setVoorraadSelect();
    }
}